import './App.css';

function App() {
  // get req.query from url
  const urlParams = new URLSearchParams(window.location.search);
  // check if urlParams has a specific query
  var name = '';

  // MECARD:N:M. Rafli Akbar;TEL:085155335112;EMAIL:rafli@cws.co.id;URL:cws.co.id/?MRA12;;;
  if (urlParams.has('MRA12')) {
    name = "Muhammad Rafli Akbar";
    return (
      <div className="App">
        <h1 style={{ marginTop: "100px" }}>Hello {name}</h1>
      </div>
    );
  // MECARD:N:M. Daffa Abdullah;TEL:085155431738;EMAIL:daffa@cws.co.id;URL:cws.co.id/?MDA38;;;
  } else if (urlParams.has('MDA38')) {
    name = "Muhammad Daffa Abdullah";
    return (
      <div className="App">
        <h1 style={{ marginTop: "100px" }}>Hello {name}</h1>
      </div>
    );
  // MECARD:N:Anna Maulidita Widy Anggraena;TEL:081243714829;EMAIL:anna@cws.co.id;URL:cws.co.id/?AMWA29;;;
  } else if (urlParams.has('AMWA29')) {
    name = "Anna Maulidita Widy Anggraena";
    return (
      <div className="App">
        <h1 style={{ marginTop: "100px" }}>Hello {name}</h1>
      </div>
    );
  // MECARD:N:Dini Meindo Rizkiyanti;TEL:085780960951;EMAIL:dini@cws.co.id;URL:cws.co.id/?DMR51;;;
  } else if (urlParams.has('DMR51')) {
    name = "Dini Meindo Rizkiyanti";
    return (
      <div className="App">
        <h1 style={{ marginTop: "100px" }}>Hello {name}</h1>
      </div>
    );
  // MECARD:N:Erik Mariono;TEL:0818191100;EMAIL:erik@cws.co.id;URL:cws.co.id/?EM00;;;
  } else if (urlParams.has('EM00')) {
    name = "Erik Mariono";
    return (
      <div className="App">
        <h1 style={{ marginTop: "100px" }}>Hello {name}</h1>
      </div>
    );
  // MECARD:N:Suci Dwiyanti;TEL:081802725437;EMAIL:suci@cws.co.id;URL:cws.co.id/?SD37;;;
  } else if (urlParams.has('SD37')) {
    name = "Suci Dwiyanti";
    return (
      <div className="App">
        <h1 style={{ marginTop: "100px" }}>Hello {name}</h1>
      </div>
    );
  // MECARD:N:Rafly Adhyra Irawan;TEL:081382447595;EMAIL:awan@cws.co.id;URL:cws.co.id/?RAI95;;;
  } else if (urlParams.has('RAI95')) {
    name = "Rafly Adhyra Irawan";
    return (
      <div className="App">
        <h1 style={{ marginTop: "100px" }}>Hello {name}</h1>
      </div>
    );
  // MECARD:N:Krisna Sanjaya;TEL:081315195224;EMAIL:kisan@cws.co.id;URL:cws.co.id/?KS24;;;
  } else if (urlParams.has('KS24')) {
    name = "Krisna Sanjaya";
    return (
      <div className="App">
        <h1 style={{ marginTop: "100px" }}>Hello {name}</h1>
      </div>
    );
  // MECARD:N:Krisna Sadewa;TEL:081282008142;EMAIL:krisna@cws.co.id;URL:cws.co.id/?KS42;;;
  } else if (urlParams.has('KS42')) {
    name = 'Krisna Sadewa';
    return (
      <div className="App">
        <h1 style={{ marginTop: '100px' }}>Hello {name}</h1>
      </div>
    );
  } else {
    return (
      <div className="App">
        
      </div>
    );
  }
}

export default App;
